<template>
  <div>
    <v-card :loading="$store.state.layout.loading" elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="3" class="d-none d-md-block" />
              <v-col md="6">
                <validation-provider v-slot="{ errors }" vid="descricao">
                  <v-text-field v-model="tabelaNova.descricao" outlined dense :readonly="readonly" hide-details="auto"
                    :disabled="$store.state.layout.loading" label="Nome" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="3" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="3" class="d-none d-md-block" />

              <v-col md="2" class="d-flex justify-center">
                <v-switch v-model="tabelaNova.ativo" class="mt-0 pt-0" :readonly="readonly" :disabled="$store.state.layout.loading"
                  :label="descricaoAtivo" />
              </v-col>
              <v-col md="2" class="d-flex justify-center">
                <v-switch v-model="tabelaNova.padrao" class="mt-0 pt-0" :readonly="readonly" :disabled="$store.state.layout.loading"
                  label="Padrão" />
              </v-col>
              <v-col md="2" class="d-flex justify-center">
                <v-switch v-model="tabelaNova.tem_convenio" class="mt-0 pt-0" :readonly="readonly"
                  :disabled="$store.state.layout.loading" label="Convênio" />
              </v-col>

              <v-col md="3" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="3" class="d-none d-md-block" />
              <v-col md="2" cols="12">
                <validation-provider v-slot="{ errors }" vid="tempo">
                  <v-text-field v-model="tabelaNova.tempo" outlined :readonly="readonly" dense hide-details="auto"
                    :disabled="$store.state.layout.loading" label="Tempo em minutos" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="2" cols="12">
                <validation-provider v-slot="{ errors }" vid="tempo_fracional" v-show="!tabelaNova.tem_convenio">
                  <v-text-field v-model="tabelaNova.tempo_fracional" outlined :readonly="readonly" dense
                    hide-details="auto" :disabled="$store.state.layout.loading" label="Tempo fracional em minutos"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="2" cols="12">
                <validation-provider v-slot="{ errors }" vid="minutos_tolerancia" v-show="!tabelaNova.tem_convenio">
                  <v-text-field v-model="tabelaNova.minutos_tolerancia" outlined :readonly="readonly" dense
                    hide-details="auto" :disabled="$store.state.layout.loading" label="Minutos de tolerância"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="3" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="3" class="d-none d-md-block" />
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="valor">
                  <v-text-field v-model="tabelaNova.valor" outlined dense :readonly="readonly" prefix="R$"
                    hide-details="auto" :disabled="$store.state.layout.loading" label="Valor" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="valor_adicional" v-show="!tabelaNova.tem_convenio">
                  <v-text-field v-model="tabelaNova.valor_adicional" outlined dense :readonly="readonly" prefix="R$"
                    hide-details="auto" :disabled="$store.state.layout.loading" label="Valor adicional" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="3" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="3" class="d-none d-md-block" />
              <v-col md="2" cols="12">
                <validation-provider v-slot="{ errors }" vid="endereco.tipo_veiculo_id">
                  <autocomplete-tipo-veiculos v-model="tabelaNova.tipo_veiculo_id" :readonly="readonly"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="2" cols="12" v-show="false">
                <validation-provider v-slot="{ errors }" vid="dia_id">
                  <autocomplete-dias v-model="tabelaNova.dia_id" :readonly="readonly"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col md="2" cols="12" v-show="tabelaNova.tem_convenio">
                <validation-provider v-slot="{ errors }" vid="convenio_id">
                  <autocomplete-convenio v-model="tabelaNova.convenio_id" :readonly="readonly" :error-messages="errors"
                    :areaId="areaId" />
                </validation-provider>
              </v-col>
              <v-col md="3" class="d-none d-md-block" />
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar :temPermissao="
              $store.getters['usuario/temPermissao']('editar.tabelas')
            " :disabled="salvarDisabled" :carregando="carregandoSalvar" @click="$emit('salvar', tabelaNova)" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    salvarDisabled: {
      type: Boolean,
      default: false,
    },
   
    tabela: {
      type: Object,
      default: () => {
        return {};
      },
    },
    areaId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      tabelaNova: {
        descricao: null,
        ativo: true,
        tem_convenio: false,
        padrao: false,
        tempo: null,
        valor: null,
        dia_id: 1,
        tipo_veiculo_id: 1,
        minutos_tolerancia: null,
        valor_adicional: null,
        tempo_fracional: null,
        convenio_id: null,
      },
    };
  },
  computed: {
    descricaoAtivo() {
      return this.tabelaNova.ativo ? "Ativo" : "Inativo";
    },
  },
  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    tabela(tabela) {
      this.tabelaNova = tabela;
    },

    'tabelaNova.tem_convenio'(novo) {
      if (novo == false) {
        this.tabelaNova.tempo_fracional = 0
        this.tabelaNova.minutos_tolerancia = 0
        this.tabelaNova.valor_adicional = 0
        this.convenio_id = 0
        this.tabelaNova.dia_id = 1
        this.tabelaNova.tem_convenio = 0
      }
    },

  },
};
</script>
